<template>
  <v-parallax :src="backgroundImage" style="height: 100%">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" lg="4" md="4" sm="8">
        <v-card class="elevation-5 pa-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <localization/>
          </v-card-actions>
          <v-card-text>
            <div class="layout column align-center">
              <img alt="App Logo" height="120" :src="require('@/../public/img/icons/LOGO.png')" width="120">
              <h1 class="text-center my-4 primary--text">{{ appTitle }}</h1>
            </div>
            <v-form>
              <v-text-field v-model="user.name" :label="$t('login.name')" append-icon="mdi-account" autocomplete="username" name="fullname" required
                            type="text"/>
              <v-text-field v-model="user.email" :label="$t('login.email')" append-icon="mdi-email" autocomplete="username" name="email" required
                            type="email"/>
              <v-text-field v-model="user.phone" :label="$t('login.phone')" append-icon="mdi-phone" autocomplete="phone" name="phone" required
                            type="text"/>
              <v-text-field v-model="user.id_card" :label="$t('login.id_card')" append-icon="mdi-card" autocomplete="card" name="card" required
                            type="text"/>
              <v-text-field v-model="user.password" :label="$t('login.password')" append-icon="mdi-lock" autocomplete="new-password" name="password"
                            required type="password"/>
              <v-text-field v-model="user.password_conf" :label="$t('login.confirm')" append-icon="mdi-lock" autocomplete="new-password" name="confirm" required
                            type="password"/>
            </v-form>
            <v-checkbox
                v-model="isAcceptCondition"
                color="primary"
                hide-details
            >
              <template v-slot:label>
                <div>
                  Tôi đồng ý với các
                  <a
                      target="_blank"
                      href="#"
                      @click.stop
                      v-on="on"
                  >
                    điều khoản & điều kiện.
                  </a>
                </div>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn :to="{name: 'Login'}" color="primary" outlined>
              {{ $t('login.login') }}
            </v-btn>
            <v-spacer/>
            <v-btn :loading="loading" color="primary" @click="register()" :disabled="!isAcceptCondition">
              {{ $t('login.register') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import Localization from '@/admin/widget/AppLocalization.vue';
import config from '@/config'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Register',
  components: {Localization},
  data: () => ({
    appTitle: config.appName,
    loading: false,
    user: {
      name: '',
      email: '',
      phone: '',
      id_card: '',
      password: '',
      password_conf: '',
    },
    isAcceptCondition: true
  }),
  computed: {
    ...mapGetters({
      backgroundImage: 'settings/backgroundImage'
    })
  },
  methods: {
    ...mapActions({
      userRegister: 'user/register'
    }),
    async register() {
      if(this.user.id_card.trim().length === 0 || this.user.phone.trim().length === 0) {
        this.noticeError('Phone, Identity Card required!');
        return;
      }
      let res = await this.userRegister(this.user);
      if (res.data && res.data.error === false) {
        this.$router.push({name: 'Login', params: this.user})
      }
    },
  },
};
</script>
<style>
.v-parallax__image-container img {
  bottom: 0;
  left: 0;
  filter: brightness(35%);
  position: fixed;
  right: 0;
  top: 0;
  transform: none !important;
  transition: none;
  width: 100%;
  height: 100%;
}
</style>
